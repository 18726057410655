import 'core-js';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './polyfills';
import CoobeeApp from './CoobeeApp';
import * as serviceWorker from './serviceWorker';
import fallback from './fallback';

try {
  ReactDOM.render(<CoobeeApp />, document.getElementById('root'));
} catch (e) {
  fallback(e);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
