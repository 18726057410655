import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import IntlMessages from '../../components/utility/intlMessages';
import Scrollbar from '../../components/utility/customScrollBar';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { getInitials } from '../../helpers/user';
import Avatar from '../../components/Avatar';

class TopbarMessage extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  getUrlFragment = parentBundle => (parentBundle === 'recommendation' ? 'recommendation' : 'task');

  render() {
    const { customizedTheme, comments } = this.props;
    const content = (
      <TopbarDropdownWrapper className="topbarMessage withImg">
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.message" />
          </h3>
        </div>
        <div className="isoDropdownBody">
          <Scrollbar style={{ height: 300 }}>
            {comments.map(comment => (
              <Link key={comment.id} to={`/${this.getUrlFragment(comment.parentBundle)}/${comment.target}`}>
                <div className="isoDropdownListItem">
                  <div className="isoImgWrapper">
                    <Avatar src={comment.avatar} name={comment.name} />
                  </div>

                  <div className="isoListContent">
                    <div className="isoListHead">
                      <h5 dangerouslySetInnerHTML={{ __html: getInitials(comment.name) }} />
                      <span className="isoDate">{moment.unix(comment.time).fromNow()}</span>
                    </div>
                    <p>{comment.message}</p>
                  </div>
                </div>
              </Link>
            ))}
          </Scrollbar>
        </div>
        <a className="isoViewAllBtn" href="# ">
          <IntlMessages id="topbar.viewAll" />
        </a>
      </TopbarDropdownWrapper>
    );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomRight"
      >
        <div className="isoIconWrapper">
          <i
            className="ion-chatbubbles"
            style={{ color: customizedTheme.textColor }}
          />
          {comments.length > 0 && (<span>{comments.length}</span>)}
        </div>
      </Popover>
    );
  }
}

TopbarMessage.propTypes = {
  customizedTheme: PropTypes.shape({}).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect(state => ({
  ...state.App,
  comments: state.Notifications.comments,
  customizedTheme: state.ThemeSwitcher.topbarTheme,
}))(TopbarMessage);
