const actions = {
  GET_BRANDING: 'GET_BRANDING',
  SET_BRANDING: 'SET_BRANDING',
  getBranding: () => ({
    type: actions.GET_BRANDING,
  }),
  setBranding: branding => ({
    type: actions.SET_BRANDING,
    branding,
  }),
};

export default actions;
