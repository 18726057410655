const actions = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  SET_COMMENTS: 'SET_COMMENTS',
  setNotifications: notifications => ({
    type: actions.SET_NOTIFICATIONS,
    notifications,
  }),
  MARK_NOTIFICATION_READ: 'MARK_NOTIFICATION_READ',
  markRead: id => ({
    type: actions.MARK_NOTIFICATION_READ,
    id,
  }),
  GET_UNREAD_TASKS: 'GET_UNREAD_TASKS',
  SET_UNREAD_TASKS: 'SET_UNREAD_TASKS',
  setUnreadTasks: tasks => ({
    type: actions.SET_UNREAD_TASKS,
    tasks,
  }),
  SET_UNSEEN_PHOTOS: 'SET_UNSEEN_PHOTOS',
  setUseenPhotos: photos => ({
    type: actions.SET_UNSEEN_PHOTOS,
    photos,
  }),
  GET_UNREAD_COMMENTS: 'GET_UNREAD_COMMENTS',
  SET_UNREAD_COMMENTS: 'SET_UNREAD_COMMENTS',
  setUnreadComments: comments => ({
    type: actions.SET_UNREAD_COMMENTS,
    comments,
  }),
  GET_UNACCEPTED_TASKS: 'GET_UNACCEPTED_TASKS',
  SET_UNACCEPTED_TASKS: 'SET_UNACCEPTED_TASKS',
  MARK_TASK_READ: 'MARK_TASK_READ',
  markTaskRead: id => ({
    type: actions.MARK_TASK_READ,
    id,
  }),
  UNSET_NOTIFICATION: 'UNSET_NOTIFICATION',
  UNSET_COMMENT: 'UNSET_COMMENT',
  MARK_COMMENT_READ: 'MARK_COMMENT_READ',
  markCommentRead: id => ({
    type: actions.MARK_COMMENT_READ,
    id,
  }),
  PUSH_COMMENT: 'PUSH_COMMENT',
  pushComment: comment => ({
    type: actions.PUSH_COMMENT,
    comment,
  }),
  PUSH_TASK: 'PUSH_TASK',
  pushTask: task => ({
    type: actions.PUSH_TASK,
    task,
  }),
};

export default actions;
