import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings';
import logo from '../../assets/images/logo.svg';
import smallLogo from '../../assets/images/small-logo.svg';

const Logo = ({ collapsed, branding }) => {
  const getLogo = () => branding.logo === null ? logo : branding.logo;

  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/">
              <img src={smallLogo} alt={siteConfig.siteName} />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/"><img src={getLogo()} alt={siteConfig.siteName} /></Link>
        </h3>
      )}
    </div>
  );
};

Logo.propTypes = {
  collapsed: PropTypes.bool,
  branding: PropTypes.shape({}).isRequired,
};

Logo.defaultProps = {
  collapsed: false,
};

const mapStateToProps = state => ({
  branding: state.Branding,
});

export default connect(mapStateToProps)(Logo);
