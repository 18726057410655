import styled from 'styled-components';
import { palette } from 'styled-theme';

const Wrapper = styled.div`
  .ant-form-item-control {
    line-height: 1.4;

    input.ant-input {
      height: 42px;
    }
    
    label {
      line-height: 30px;
    }

    .ant-input {
      padding: 1.3rem 1rem .2rem;
      font-size: 16px;

      ::-webkit-input-placeholder,
      ::placeholder {
        opacity: 0 !important;
        transition: inherit;
        color: transparent !important;
      }
    }

    .ant-form-item-children-icon {
      top: 1.3rem !important;
    }

    .ant-select-selection {
      font-size: 16px;
      height: 42px;

      &__rendered {
        height: 42px;
      }

      &__placeholder {
        color: ${palette('text', 0)};
        font-weight: normal;
      }
      
      &__choice {
        &:first-child:not(:nth-last-child(2)) {
          .ant-badge-status-text {
            max-width: 60px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .ant-select + label {
      z-index: -2;
    }

    &.has-value {
      .ant-select + label {
        z-index: auto;
      }

      .ant-select-selection-selected-value {
        padding-top: .7rem;

        .ant-badge-status-dot {
          display: none;
        }

        .ant-badge-status-text {
          margin-left: 4px;
          font-size: 16px;
        }
      }

      .ant-select-selection--multiple {
        padding-top: .7rem;

        .ant-badge-status-dot {
          display: none;
        }
        .ant-badge-status-text {
          margin-left: 0;
        }
      }

      .ant-select-selection__placeholder {
        display: none !important;
      }
    }



    .ant-select + label,
    input + label {
      left: 1rem;
      top: .4rem;
      position: absolute;
      font-weight: 400;
    }

    label, input {
      transition: all 0.2s;
      touch-action: manipulation;
    }

    &:not(.has-value) input + placeholder,
    input:placeholder-shown + label,
    .ant-select + label,
    .input-group.blur + label {
      cursor: text;
      max-width: 66.66%;
      white-space: nowrap;
      text-overflow: ellipsis;
      transform-origin: left bottom;
      color: ${palette('text', 0)};
    }

    textarea + label {
      font-weight: 400;
      left: 1rem;
      top: .4rem;
    }

    &:not(.has-value) textarea + placeholder,
    textarea:placeholder-shown + label {
      cursor: text;
      max-width: 66.66%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      color: ${palette('text', 0)};
    }

    input:focus::-webkit-input-placeholder,
    textarea:focus::-webkit-input-placeholder,
    input:focus::placeholder,
    textarea:focus::placeholder {
      opacity: 1 !important;
    }


    input:focus::-ms-input-placeholder,
    textarea:focus::-ms-input-placeholder {
      opacity: 1 !important;
    }

    input:not(:placeholder-shown) + label,
    .input-group.focus + label,
    input:focus + label {
      transform: translate(0, -10px) scale(1) !important;
      cursor: pointer;
      padding-right: 11px;
      color: #989898;
      font-size: .8em !important;
      position: absolute;
      z-index: 4;
    }

    &.has-value input + label,
    &.has-value .ant-select + label {
      transform: translate(0, -10px) scale(1) !important;
      cursor: pointer;
      padding-right: 11px;
      color: #989898;
      font-size: .8em !important;
      position: absolute;
      z-index: 4;
    }

    textarea:not(:placeholder-shown) + label,
    textarea:focus + label {
      cursor: pointer;
      padding-right: 11px;
      color: #989898;
      left: 0;
      top: -2px;
      position: absolute;
      transform: scale(.8) !important;
    }
  }


`;

export default Wrapper;
