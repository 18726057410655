import Enlang from './entries/en-US';
import RuLang from './entries/ru-RU';

const AppLocale = {
  en: Enlang,
  ru: RuLang,
};
if (Intl && !Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/ru'); // Add locale data for de
}

if (Intl && !Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/ru'); // Add locale data for de
}

export default AppLocale;
