// Get image from backend with authorization.
import Auth from '../authentication/OAuth2';
import request from './request';
import { getBase64 } from './file';

const getImage = async (src) => {
  if (typeof src !== 'string' || src.length === 0) {
    return null;
  }
  try {
    const token = await Auth.getFreshTokenPromise();
    const response = await request
      .get(src)
      .responseType('blob')
      .set('Accept', 'image/jpeg')
      .set('Authorization', `Bearer ${token}`);
    const data = await getBase64(response.body, true);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default getImage;
