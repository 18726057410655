import actions from './actions';

const initialState = {
  logo: null,
  copyright: null,
  signinBack: null,
};

const brandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_BRANDING:
      return {
        ...state,
        ...action.branding,
      };
    default:
      return state;
  }
};

export default brandingReducer;
