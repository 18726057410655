import actions from './actions';

const initialState = {
  taskFilter: null,
  statusFilter: [],
  pager: null,
  idFilter: null,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      const path = action.payload.location.pathname;
      if (path !== '/' && path.indexOf('/task') !== 0) {
        return initialState;
      }
      return state;
    case actions.SET_FILTERS:
      return ({
        ...state,
        taskFilter: action.taskFilter,
      });
    case actions.SET_STATUS_FILTER:
      return ({
        ...state,
        statusFilter: action.statusFilter,
      });
    case actions.SET_PAGER:
      return ({
        ...state,
        pager: action.pager,
      });
    case actions.RESET_FILTERS:
      return initialState;
    case actions.SET_TASK_ID_FILTER:
      return ({
        ...state,
        idFilter: action.ids,
      });
    case actions.RESET_TASK_ID_FILTER:
      return ({
        ...state,
        idFilter: null,
      });
    default:
      return state;
  }
};

export default filterReducer;
