const actions = {
  START_TRACKING: 'START_TRACKING',
  startTracking: task => ({
    type: actions.START_TRACKING,
    task,
  }),
  FINISH_TRACKING: 'FINISH_TRACKING',
  finishTracking: () => ({
    type: actions.FINISH_TRACKING,
  }),
  PUT_TRACKING: 'PUT_TRACKING',
  GET_TRACKING: 'GET_TRACKING',
  LOAD_TRACKING: 'LOAD_TRACKING',
};

export default actions;
