import map from 'lodash/map';
import React from 'react';
import {
  DatePicker,
  Input,
  TimePicker,
  Select,
  Badge,
  Switch,
  Checkbox,
} from 'antd';
import MaskedInput from 'antd-mask-input';

import { htmlSpecialCharsDecode } from '../../helpers/utility';

import FormItem from './FormItem';

const { Option } = Select;
const { TextArea } = Input;

const getHelp = (help, submittedError, touchedError, hasError) => {
  if (submittedError || touchedError) {
    return hasError;
  }
  if (help) {
    return help;
  }
  return false;
};

const CreateAntField = Component => ({
  field,
  form,
  hasFeedback,
  label,
  tasklabel,
  selectOptions,
  type,
  formitem,
  className,
  help,
  ...props
}) => {
  const touched = form.touched[field.name];
  const submitted = form.submitCount > 0;
  const hasError = form.errors[field.name];
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  const onInputChange = ({ target: { value } }) =>
    form.setFieldValue(field.name, value);
  const onChange = value => form.setFieldValue(field.name, value);
  const onBlur = () => form.setFieldTouched(field.name, true);
  return (
    <div className="field-container">
      <FormItem
        label={label}
        colon={false}
        className={className}
        hasFeedback={
          (hasFeedback && submitted) || (hasFeedback && touched)
        }
        help={getHelp(help, submittedError, touchedError, hasError)}
        validateStatus={submittedError || touchedError ? 'error' : 'success'}
        {...formitem}
        {...field}
      >
        {tasklabel && tasklabel}
        <Component
          {...field}
          {...props}
          onBlur={onBlur}
          type={type}
          onChange={type ? onInputChange : onChange}
          placeholder={selectOptions ? label : '...'}
          id={field.name}
        >
          {selectOptions &&
            map(selectOptions, option => (
              <Option key={option.value}>
                <Badge status={option.value} text={htmlSpecialCharsDecode(option.name)} />
              </Option>
            ))}
        </Component>
      </FormItem>
    </div>
  );
};

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntMaskedInput = CreateAntField(MaskedInput);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntTextArea = CreateAntField(TextArea);
export const AntSwitch = CreateAntField(Switch);
export const AntCheckbox = CreateAntField(Checkbox);
