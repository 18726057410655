import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Auth from '../../authentication/OAuth2';
import { checkAccess } from '../../helpers/user';

const formatError = (error) => {
  switch (error) {
    case 'invalid_credentials':
      return 'credentials';
    default:
      return 'unknown';
  }
};

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    try {
      const response = yield call(Auth.logInRequest, payload);
      if (response.statusCode === 200 && 'access_token' in response.body) {
        // Successful login;
        Auth.storeTokens(response.body);
        yield put({
          type: actions.CHECK_AUTHORIZATION,
        });
      }
    } catch (e) {
      // Error handle.
      let error = 'unknown';
      if ('response' in e && 'body' in e.response && 'error' in e.response.body) {
        error = formatError(e.response.body.error);
      }
      yield put({
        type: actions.LOGIN_ERROR,
        error,
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* () { });
}


export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield call(Auth.logout);
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    try {
      const response = yield call(Auth.checkLogin);
      if (response && 'login' in response) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          profile: response,
        });
        yield put({ type: 'GET_CONFIG' });
        yield put({ type: 'GET_NOTIFICATIONS' });
        yield put({ type: 'GET_UNREAD_TASKS' });
        yield put({ type: 'GET_UNREAD_COMMENTS' });
        yield put({ type: 'GET_TRACKING' });
        if (checkAccess(response, ['dispatcher'])) {
          yield put({ type: 'GET_UNACCEPTED_TASKS' });
        }
        if (typeof window.jivo_destroy === 'function') {
          window.jivo_destroy();
        }
      }
    } catch (e) {
      yield put({ type: actions.ACCOUNT_READY });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
