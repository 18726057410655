const fallback = (e) => {
  console.log(e);
  const body = document.querySelector('body');
  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  body.innerHTML = `<div style="text-align: center">
<h1>Что-то пошло не так!</h1>
<div>Возможно, ваш браузер устарел.</div>
<div>${isIE ? 'Попробуйте установить <a href="https://www.google.com/intl/ru/chrome/" target="_blank">Google Chrome</a>' : ''}</div>
</div>`;
};

export default fallback;
