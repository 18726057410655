import React from 'react';
import styled from 'styled-components';
import { siteConfig } from '../settings';
import Spin from './uielements/spin';
import logo from '../assets/images/logo.svg';

const Wrapper = styled.div`
  .global-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .isoLogoWrapper {
    margin-bottom: 50px;
  }
`;

const GlobalLoader = () => (
  <Wrapper>
    <div className="global-loader">
      <div className="isoLogoWrapper">
        <h3>
          <img src={logo} alt={siteConfig.siteName} />
        </h3>
      </div>
      <Spin size="large" />
    </div>
  </Wrapper>
);

export default GlobalLoader;
