import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Auth from '../../authentication/OAuth2';
import request from '../../helpers/request';
import { toBool } from '../../helpers/utility';

export function* setConfig() {
  yield takeEvery(actions.GET_CONFIG, function* () {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const req = () => request
        .get('/api/global-config')
        .set('Authorization', `Bearer ${accessToken}`);
      const response = yield call(req);
      // Well format boolean values.
      const modules = {};
      Object.keys(response.body.modules).forEach((key) => {
        modules[key] = toBool(response.body.modules[key]);
      });
      if (!response.body.objectInfo) {
        delete response.body.objectInfo;
      }
      yield put({
        type: actions.SET_CONFIG,
        config: {
          ...response.body,
          modules,
          noWorkers: toBool(response.body.noWorkers),
          disableObjectTitle: toBool(response.body.disableObjectTitle),
        },
      });
      yield put({ type: 'ACCOUNT_READY' });
    } catch (e) {
      // Error handle.
      console.log(e);
      yield put({
        type: actions.SET_CONFIG,
        config: null,
      });
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(setConfig),
  ]);
}
