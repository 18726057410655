import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import theme from '../settings/themes/themedefault';
import getImage from '../helpers/getImage';

const CustomAvatar = ({ color, src, ...props }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchSrc = async () => {
      if (src) {
        const loadedData = await getImage(src);
        setData(loadedData);
      }
    };
    fetchSrc();
  }, [src]);
  return (
    <Avatar
      fgColor={color}
      {...props}
      round
      src={data}
      color="#FFFFFF"
      size="40px"
      style={{
        border: `1px solid ${color}`,
        boxSizing: 'content-box',
      }}
    />
  );
};

CustomAvatar.propTypes = {
  color: PropTypes.string,
  src: PropTypes.string,
};

CustomAvatar.defaultProps = {
  color: theme.palette.primary[0],
  src: null,
};

export default CustomAvatar;
