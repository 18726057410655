import styled from 'styled-components';

const Wrapper = Component => styled(Component)`
  .ant-select {
    width: 100%;
  }
  
  label {
    font-weight: 700;
    margin-bottom: .25rem;
    display: block;
  }
  
  .ant-modal-footer {
    border-top: 0;
  }
  
  .transition-form {
    label {
      display: initial;
    }
    
    .ant-checkbox-wrapper {
      margin-right: 1rem;
    }
    
    .ant-form-item {
      margin-bottom: .5rem;
      margin-top: .5rem;
    }
  }
`;

export default Wrapper;
