import jsCookie from 'js-cookie';
import superagent from '../../helpers/request';
import { getTokenPromise } from '../../helpers/postRequest';

class OAuth2 {
  logInRequest = ({ username, password }) => {
    return superagent
      .post('/oauth/token?_format=json')
      .send({
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username,
        password,
        // You must specify allowed scopes explicitly
        // otherwise tokens won't get proper permissions.
        scope: ['authenticated', 'dispatcher', 'client'],
      })
      .set('Content-Type', 'application/x-www-form-urlencoded');
  };

  /**
   * Store token info in local storage.
   * @param access_token
   * @param expires_in
   * @param refresh_token
   */
  storeTokens = ({ access_token, expires_in, refresh_token }) => {
    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('expires', (new Date()).getTime() + (parseInt(expires_in, 10) * 1000));
    localStorage.setItem('refreshToken', refresh_token);
  };

  getFreshTokenPromise = () => (
    new Promise((resolve, reject) => {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        reject();
        return;
      }
      const expires = localStorage.getItem('expires');
      // Add two seconds to consider possible time lag.
      if (expires > (new Date()).getTime() + 2000) {
        resolve(token);
        return;
      }
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        reject();
        return;
      }
      superagent.post('/oauth/token')
        .send({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
        })
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .then((response) => {
          this.storeTokens(response.body);
          resolve(response.body.access_token);
        })
        .catch(error => reject(error));
    })
  );


  logout = () => (
    new Promise((resolve) => {
      this.getFreshTokenPromise().then(() =>
        getTokenPromise().then((token) => {
          superagent
            .post('/user/token/revoke')
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)
            .set('X-CSRF-TOKEN', token)
            .query({ _format: 'json' })
            .end((error, response) => {
              if (error) {
                console.error('Logout error:', error);
                if (response && response.body && response.body.message) {
                  console.error('Response:', response);
                }
              }
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              localStorage.removeItem('expires');
              jsCookie.remove('X-CSRF-Token');
              resolve();
            });
        }),
      );
    })
  );

  checkLogin = () => (
    new Promise((resolve, reject) => {
      this.getFreshTokenPromise()
        .then((token) => {
          superagent
            .get('/user/me?_format=json')
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${token}`)
            .end((error, response) => {
              if (error) {
                console.error('error:', error);
                if (response && response.body && response.body.message) {
                  console.error('Response:', response.body.message);
                }
                reject(false);
              }
              resolve(response.body);
            });
        })
        .catch(() => reject(false));
    })
  );
}

export default new OAuth2();
