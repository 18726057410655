import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Auth from '../../authentication/OAuth2';
import request from '../../helpers/request';
import { getToken } from '../../helpers/postRequest';

export function* startTracking() {
  yield takeEvery(actions.START_TRACKING, function* ({ task }) {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const token = yield call(getToken);
      const req = () => request
        .post('/api/time-tracking')
        .set('X-CSRF-Token', token)
        .set('Authorization', `Bearer ${accessToken}`)
        .send({ task });
      yield call(req);
      yield put({
        type: actions.PUT_TRACKING,
        task,
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export function* getTracking() {
  yield takeEvery(actions.GET_TRACKING, function* () {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const req = () => request
        .get('/api/time-tracking')
        .set('Authorization', `Bearer ${accessToken}`);
      const response = yield call(req);
      yield put({
        type: actions.LOAD_TRACKING,
        ...response.body,
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(startTracking),
    fork(getTracking),
  ]);
}
