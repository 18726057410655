// Time-related transforms.

import moment from 'moment';

const addLeadingZero = (number) => {
  const string = number.toString();
  if (string.length < 2) {
    return `0${string}`;
  }
  return number;
};

export const formatTiming = (timing, seconds = false) => {
  const duration = moment.duration(parseInt(timing, 10), 'seconds');
  if (!seconds) {
    return `${addLeadingZero(duration.hours())}:${addLeadingZero(duration.minutes())}`;
  }
  return `${addLeadingZero(duration.hours())}:${addLeadingZero(duration.minutes())}:${addLeadingZero(duration.seconds())}`;
};
