import antdRu from 'antd/lib/locale-provider/ru_RU';
import ruMessages from '../locales/ru_RU.json';
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const RuLang = {
  messages: {
    ...ruMessages,
  },
  antd: antdRu,
  locale: 'ru-RU',

};
export default RuLang;
