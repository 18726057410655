import superAgent from 'superagent';
import superagentPrefix from 'superagent-prefix';
import superagentDefaults from 'superagent-defaults';

const prefix = superagentPrefix(process.env.REACT_APP_BACKEND_URL);

const superagent = superagentDefaults(superAgent);

superagent.use(prefix);

export default superagent;
