import jsCookie from 'js-cookie';
import request from './request';

export const getToken = async () => {
  let token = jsCookie.get('X-CSRF-Token');
  if (!token) {
    const response = await request.get('/session/token');
    token = response.text;
    jsCookie.set('X-CSRF-Token', token, {
      expires: new Date(new Date().getTime() + (360 * 1000)),
    });
  }
  return token;
};

export const getTokenPromise = () => (
  new Promise((resolve) => {
    let token = jsCookie.get('X-CSRF-Token');
    if (token) {
      resolve(token);
    }
    request.get('/session/token')
      .end((error, response) => {
        token = response.text;
        jsCookie.set('X-CSRF-Token', token, {
          expires: new Date(new Date().getTime() + (360 * 1000)),
        });
        resolve(token);
      });
  })
);
