import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 1rem;
  
  @media (max-width: 480px) {
    .ant-btn {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
`;

export default Wrapper;
