import { Dropdown, Menu } from 'antd';
import DropdownWrapper, { DropdownMenus } from './styles/dropdown.style';

const Dropdowns = DropdownWrapper(Dropdown);

const DropdownButton = DropdownWrapper(Dropdown.Button);
const DropdownMenu = DropdownMenus(Menu);
const MenuItem = DropdownMenus(Menu.Item);
const SubMenu = DropdownMenus(Menu.SubMenu);

export default Dropdowns;

export { DropdownButton, DropdownMenu, MenuItem, SubMenu };
