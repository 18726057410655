import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import configSaga from './globalConfig/saga';
import brandingSaga from './branding/saga';
import notificationSaga from './notifications/saga';
import timeTrackingSaga from './timeTracking/saga';
// import contactSagas from './contacts/saga';
// import invoicesSagas from './invoice/saga';
// import mailSagas from './mail/saga';
// import notesSagas from './notes/saga';
// import todosSagas from './todos/saga';
// import ecommerceSaga from './ecommerce/saga';
// import cardsSagas from './card/saga';
// import chatSagas from './chat/sagas';
// import youtubeSearchSagas from './youtubeSearch/sagas';
// import devSagas from '../customApp/redux/sagas';
// import articles from './articles/sagas';
// import investors from './investors/sagas';
// import scrumBoardSaga from './scrumBoard/saga';
// import profileSaga from './profile/saga';
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    configSaga(),
    brandingSaga(),
    notificationSaga(),
    timeTrackingSaga(),
    // contactSagas(),
    // mailSagas(),
    // notesSagas(),
    // todosSagas(),
    // ecommerceSaga(),
    // cardsSagas(),
    // invoicesSagas(),
    // chatSagas(),
    // youtubeSearchSagas(),
    // devSagas(),
    // articles(),
    // investors(),
    // scrumBoardSaga(),
    // profileSaga(),
  ]);
}
