const actions = {
  GET_CONFIG: 'GET_CONFIG',
  SET_CONFIG: 'SET_CONFIG',
  setConfig: config => ({
    type: actions.SET_CONFIG,
    config,
  }),
  SET_TYPES_OF_WORK: 'SET_TYPES_OF_WORK',
  setTypesOfWork: types => ({
    type: actions.SET_TYPES_OF_WORK,
    types,
  }),
  SET_URGENCY: 'SET_URGENCY',
  setUrgency: urgency => ({
    type: actions.SET_URGENCY,
    urgency,
  }),
};

export default actions;
