import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Modal, Row } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Field, Form, Formik } from 'formik';
import Wrapper from './timeTracker.style';
import { formatTiming } from '../../helpers/time';
import Button from '../../components/uielements/button';
import ModalWrapper from '../Tasks/actionModal.style';
import IntlMessages from '../../components/utility/intlMessages';
import FormikEffect from '../../helpers/FormikEffect';
import { AntInput } from '../../components/uielements/AntFields';
import { isEmpty } from '../../helpers/utility';
import { getToken } from '../../helpers/postRequest';
import Auth from '../../authentication/OAuth2';
import request from '../../helpers/request';
import actions from '../../redux/timeTracking/actions';

const WrappedModal = ModalWrapper(Modal);

const getDefaultTime = (start) => {
  const now = Math.round((new Date()).getTime() / 1000);
  const duration = moment.duration(now - parseInt(start, 10), 'seconds');
  return {
    hours: duration.hours(),
    minutes: duration.minutes(),
  };
};

const TimeTracker = ({ tracking, start, task, finishTracking }) => {
  const [time, setTime] = useState(Math.round((new Date()).getTime() / 1000));
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [disableOk, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(getDefaultTime(start))

  if (!tracking || !task) {
    return null;
  }
  setInterval(() => setTime(Math.round((new Date()).getTime() / 1000)), 1000);

  const setFormValues = (vals) => {
    let disable = false;
    if (isEmpty(vals.hours) && isEmpty(vals.minutes)) {
      disable = true;
    }
    setDisableButton(disable);
    setValues(vals);
  };

  const onChangeFormValues = ({ nextValues }) => {
    setFormValues(nextValues);
  };

  const onOk = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const accessToken = await Auth.getFreshTokenPromise();
      await request.post('/api/time-tracking/close')
        .set('X-CSRF-Token', token)
        .set('Authorization', `Bearer ${accessToken}`)
        .send(values);
      setLoading(false);
      toggleModal();
      finishTracking();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Link to={`/task/${task}`}>
        <a>
          <Button
            type="primary"
            onClick={toggleModal}
          >
            {formatTiming(time - start, true)}
          </Button>
        </a>
      </Link>
      <WrappedModal
        visible={modal}
        closable
        destroyOnClose
        title={<IntlMessages id="task.modal.manualTracking" />}
        onCancel={toggleModal}
        onOk={onOk}
        okButtonProps={{ disabled: disableOk }}
        confirmLoading={loading}
      >
        <Formik
          onSubmit={() => {}}
          initialValues={getDefaultTime(start)}
          render={() => (
            <Form>
              <FormikEffect onChange={onChangeFormValues} />
              <Row gutter={30}>
                <Col xs={24}><strong><IntlMessages id="transition_field.tracking_time" /></strong></Col>
                <Col xs={12}>
                  <Field
                    name="hours"
                    label={<IntlMessages id="transition_field.tracking_hours" />}
                    component={AntInput}
                    type="number"
                  />
                </Col>
                <Col xs={12}>
                  <Field
                    name="minutes"
                    label={<IntlMessages id="transition_field.tracking_minutes" />}
                    component={AntInput}
                    type="number"
                  />
                </Col>
              </Row>
            </Form>
          )}
        />
      </WrappedModal>
    </Wrapper>
  );
};

TimeTracker.propTypes = {
  tracking: PropTypes.bool.isRequired,
  start: PropTypes.number,
  task: PropTypes.string,
  finishTracking: PropTypes.func.isRequired,
};

TimeTracker.defaultProps = {
  start: null,
  task: null,
};

const mapStateToProps = state => ({
  tracking: state.TimeTracking.tracking,
  start: state.TimeTracking.start,
  task: state.TimeTracking.task,
});

const mapDispatchToProps = dispatch => ({
  finishTracking: () => dispatch(actions.finishTracking()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimeTracker);
