// User-related functions.
export const formatName = (profile) => {
  const nameParts = [];
  if (profile.surname) {
    nameParts.push(profile.surname);
  }
  if (profile.name) {
    nameParts.push(profile.name);
  }
  if (profile.patronimic) {
    nameParts.push(profile.patronimic);
  }
  if (nameParts.length) {
    return nameParts.join(' ');
  }
  return profile.login;
};

// Full name string to initials.
export const getInitials = (name) => {
  const pieces = name.split(' ');
  const filtered = pieces.filter(p => p.trim().length > 0);
  return filtered.map((piece, index) => {
    if (index === 0) {
      return piece;
    }
    return `${piece.substr(0, 1)}.`;
  }).join('&nbsp;');
};

/**
 * Check access of current user.
 * @param profile
 * @param access - array of allowed roles.
 * @returns {boolean}
 */
export const checkAccess = (profile, access) => {
  // Return false for invalid profile object.
  if (!profile || !Array.isArray(profile.roles)) {
    return false;
  }
  // Return true if no restrictions provided.
  if (!Array.isArray(access) || !access.length) {
    return true;
  }
  // Admin always pass.
  if (profile.roles.indexOf('administrator') >= 0) {
    return true;
  }
  // Check required roles for user.
  const intersection = profile.roles.filter(role => access.indexOf(role) >= 0);
  return intersection.length > 0;
};
