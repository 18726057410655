const options = [
  {
    key: '',
    label: 'sidebar.tasks',
    leftIcon: 'ion-clipboard',
  },
  {
    key: 'recommendations',
    label: 'sidebar.recommendations',
    leftIcon: 'anticon-safety-certificate',
    access: ['dispatcher', 'engineer', 'client', 'boss'],
  },
  {
    key: 'objects',
    label: 'sidebar.objects',
    leftIcon: 'ion-home',
  },
  {
    key: 'users',
    label: 'sidebar.users',
    leftIcon: 'ion-person',
    access: ['dispatcher'],
    children: [
      {
        key: 'users/engineer',
        label: 'sidebar.engineers',
      },
      {
        key: 'users/dispatcher',
        label: 'sidebar.dispatchers',
      },
      {
        key: 'users/client',
        label: 'sidebar.managers',
      },
      {
        key: 'users/worker',
        label: 'sidebar.workers',
      },
      {
        key: 'users/boss',
        label: 'sidebar.supervisors',
      },
    ],
  },
  {
    key: 'clients',
    label: 'sidebar.clients',
    leftIcon: 'ion-ios-people',
    access: ['dispatcher'],
  },
  {
    key: 'scheduler',
    label: 'sidebar.scheduler',
    leftIcon: 'ion-android-calendar',
    access: ['dispatcher'],
  },
  {
    key: 'analytics',
    label: 'sidebar.analytics',
    leftIcon: 'ion-ios-pie',
    access: ['dispatcher', 'boss'],
    children: [
    /*  {
        key: 'common',
        label: 'sidebar.analytics.common',
      },*/
      {
        key: 'analytics/per-object',
        label: 'sidebar.analytics.per-object',
      },
      {
        key: 'analytics/per-user',
        label: 'sidebar.analytics.per-engineer',
      },
  /*    {
        key: 'per-day',
        label: 'sidebar.analytics.per-day',
      },
      {
        key: 'per-place',
        label: 'sidebar.analytics.per-place',
      },*/
    ],
  },
  {
    key: 'admin-settings',
    access: ['dispatcher'],
    label: 'sidebar.admin',
    leftIcon: 'ion-settings',
    children: [
      {
        key: 'locations',
        label: 'topbar.add.city',
      },
      {
        key: 'types-of-work',
        label: 'sidebar.works',
      },
      {
        key: 'urgency',
        label: 'sidebar.urgency',
      },
    ],
  },
];
export default options;
