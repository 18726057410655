import actions from './actions';

const initState = {
  error: null,
  loggedIn: false,
  profile: null,
  ready: false,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        loggedIn: true,
        profile: action.profile,
      };
    case actions.LOGOUT:
      return {
        ...initState,
        ready: true,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.ACCOUNT_READY:
      return {
        ...state,
        ready: true,
      };
    default:
      return state;
  }
}
