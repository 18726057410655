import actions from './actions';
import { toBool } from '../../helpers/utility';

const initialState = {
  tracking: false,
  transition: null,
  task: null,
  start: null,
};

const timeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.PUT_TRACKING:
      return {
        ...state,
        tracking: true,
        task: action.task,
        start: Math.round((new Date()).getTime() / 1000),
      };
    case actions.FINISH_TRACKING:
      return {
        ...state,
        tracking: false,
        task: null,
        transition: null,
        start: null,
      };
    case actions.LOAD_TRACKING:
      return {
        ...state,
        tracking: toBool(action.tracking),
        start: action.start,
        task: action.task,
      };
    default:
      return state;
  }
};

export default timeReducer;
