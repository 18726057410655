import actions from './actions';

const initialState = {
  status: [],
  urgency: [],
  typeOfWork: {},
  roles: {},
  helpPhone: null,
  modules: {},
  objectInfo: 'object',
  noWorkers: false,
  disableObjectTitle: false,
  timeTracking: {},
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CONFIG:
      return {
        ...state,
        ...action.config,
      };
    case actions.SET_TYPES_OF_WORK:
      return {
        ...state,
        typeOfWork: action.types,
      };
    case actions.SET_URGENCY:
      return {
        ...state,
        urgency: action.urgency,
      };
    default:
      return state;
  }
};

export default configReducer;
