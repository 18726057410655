import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from '../../components/utility/intlMessages';
import Button from '../../components/uielements/button';
import actions from '../../redux/notifications/actions';

const Notification = ({ id, name, notification, target, markRead }) => (
  <div className="isoDropdownListItem">
    <div>
      <h5>{name}</h5>
      <p>{notification}</p>
    </div>
    <div className="notification-actions">
      <Tooltip placement="top" title={<IntlMessages id="topbar.view" />}>
        <Link to={`/task/${target}`}>
          <Button
            shape="circle"
            type="link"
            onClick={() => markRead(id)}
          >
            <i className="ion-android-open" />
          </Button>
        </Link>
      </Tooltip>
      <Tooltip placement="top" title={<IntlMessages id="topbar.delete" />}>
        <Button
          icon="close"
          shape="circle"
          type="link"
          onClick={() => markRead(id)}
        />
      </Tooltip>
    </div>
  </div>
);

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  notification: PropTypes.string,
  markRead: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  notification: null,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  markRead: id => dispatch(actions.markRead(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
