import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import Auth from '../../authentication/OAuth2';
import request from '../../helpers/request';
import { getToken } from '../../helpers/postRequest';

const toArray = (variable) => {
  if (Array.isArray(variable)) {
    return variable;
  }
  return [variable];
}

export function* setNotifications() {
  yield takeEvery(actions.GET_NOTIFICATIONS, function* () {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const req = () => request
        .get('/api/notifications')
        .set('Authorization', `Bearer ${accessToken}`);
      const response = yield call(req);
      yield put({
        type: actions.SET_NOTIFICATIONS,
        notifications: response.body.notifications,
      });
      yield put({
        type: actions.SET_COMMENTS,
        comments: response.body.comments,
      });
    } catch (e) {
      // Error handle.
      console.log(e);
      yield put({
        type: actions.SET_NOTIFICATIONS,
        notifications: [],
      });
      yield put({
        type: actions.SET_COMMENTS,
        comments: [],
      });
    }
  });
}

export function* markNotification() {
  yield takeEvery(actions.MARK_NOTIFICATION_READ, function* ({ id }) {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const token = yield call(getToken);
      const req = () => request
        .post(`/api/mark-notification-read/${id}`)
        .set('X-CSRF-Token', token)
        .set('Authorization', `Bearer ${accessToken}`)
        .send({ id });
      const response = yield call(req);
      yield put({
        type: actions.SET_NOTIFICATIONS,
        notifications: response.body,
      });
    } catch (e) {
      // Error handle.
      console.log(e);
    }
  });
}

export function* setUnreadTasks() {
  yield takeEvery(actions.GET_UNREAD_TASKS, function* () {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const req = () => request
        .get('/api/unread-tasks')
        .set('Authorization', `Bearer ${accessToken}`);
      const response = yield call(req);
      yield put({
        type: actions.SET_UNREAD_TASKS,
        tasks: toArray(response.body.tasks),
      });
      yield put({
        type: actions.SET_UNSEEN_PHOTOS,
        photos: toArray(response.body.photos),
      });
    } catch (e) {
      // Error handle.
      console.log(e);
      yield put({
        type: actions.SET_UNREAD_TASKS,
        tasks: [],
      });
    }
  });
}

export function* setUnacceptedTasks() {
  yield takeEvery(actions.GET_UNACCEPTED_TASKS, function* () {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const req = () => request
        .get('/api/unaccepted-tasks')
        .set('Authorization', `Bearer ${accessToken}`);
      const response = yield call(req);
      yield put({
        type: actions.SET_UNACCEPTED_TASKS,
        tasks: response.body,
      });
    } catch (e) {
      // Error handle.
      console.log(e);
      yield put({
        type: actions.SET_UNACCEPTED_TASKS,
        tasks: [],
      });
    }
  });
}


export function* markTaskRead() {
  yield takeEvery(actions.MARK_TASK_READ, function* ({ id }) {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const token = yield call(getToken);
      const req = () => request
        .post('/api/unread-tasks')
        .set('X-CSRF-Token', token)
        .set('Authorization', `Bearer ${accessToken}`)
        .send({ id });
      const response = yield call(req);
      yield put({
        type: actions.SET_UNREAD_TASKS,
        tasks: toArray(response.body.tasks),
      });
      yield put({
        type: actions.SET_UNSEEN_PHOTOS,
        photos: toArray(response.body.photos),
      });
      yield put({
        type: actions.UNSET_NOTIFICATION,
        id,
      });
    } catch (e) {
      // Error handle.
      console.log(e);
    }
  });
}

export function* markCommentRead() {
  yield takeEvery(actions.MARK_COMMENT_READ, function* ({ id }) {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const token = yield call(getToken);
      const req = () => request
        .post('/api/unread-comments')
        .set('X-CSRF-Token', token)
        .set('Authorization', `Bearer ${accessToken}`)
        .send({ id });
      const response = yield call(req);
      yield put({
        type: actions.SET_UNREAD_COMMENTS,
        comments: Array.isArray(response.body) ? {} : response.body,
      });
      yield put({
        type: actions.UNSET_COMMENT,
        id,
      });
    } catch (e) {
      // Error handle.
      console.log(e);
    }
  });
}

export function* setUnreadComments() {
  yield takeEvery(actions.GET_UNREAD_COMMENTS, function* () {
    try {
      const accessToken = yield call(Auth.getFreshTokenPromise);
      const req = () => request
        .get('/api/unread-comments')
        .set('Authorization', `Bearer ${accessToken}`);
      const response = yield call(req);
      yield put({
        type: actions.SET_UNREAD_COMMENTS,
        comments: Array.isArray(response.body) ? {} : response.body,
      });
    } catch (e) {
      // Error handle.
      console.log(e);
      yield put({
        type: actions.SET_UNREAD_COMMENTS,
        comments: {},
      });
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(setNotifications),
    fork(markNotification),
    fork(setUnreadTasks),
    fork(markTaskRead),
    fork(setUnacceptedTasks),
    fork(markCommentRead),
    fork(setUnreadComments),
  ]);
}
