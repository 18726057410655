import asyncComponent from './helpers/AsyncFunc';

const routes = {
  '/dashboard': {
    exact: true,
    component: asyncComponent(() => import('./containers/Dashboard')),
  },
  '/': {
    exact: true,
    component: asyncComponent(() => import('./containers/Tasks')),
  },
  '/recommendations': {
    exact: true,
    component: asyncComponent(() => import('./containers/Recommendations')),
    access: ['dispatcher', 'engineer', 'client', 'boss'],
  },
  '/help': {
    exact: true,
    component: asyncComponent(() => import('./containers/Help')),
  },
  '/add/user': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/UserForm')),
    access: ['dispatcher'],
  },
  '/user/:id/edit': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/UserForm')),
    access: ['dispatcher'],
  },
  '/objects': {
    exact: true,
    component: asyncComponent(() => import('./containers/Objects')),
  },
  '/object/:id': {
    exact: true,
    component: asyncComponent(() => import('./containers/ObjectPage')),
  },
  '/add/object': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/ObjectForm')),
  },
  '/object/:id/edit': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/ObjectForm')),
    access: ['dispatcher'],
  },
  '/add/task': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/TaskForm')),
  },
  '/add/recommendation': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/TaskForm')),
  },
  '/recommendation/:id': {
    exact: true,
    component: asyncComponent(() => import('./containers/Recommendation')),
  },
  '/task/:id': {
    exact: true,
    component: asyncComponent(() => import('./containers/Task')),
  },
  '/task/:id/edit': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/TaskForm')),
    access: ['dispatcher'],
  },
  '/task/:id/repeat': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/TaskForm')),
    access: ['dispatcher', 'client'],
  },
  '/recommendation/:id/edit': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/TaskForm')),
    access: ['dispatcher'],
  },
  '/add/client': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/ClientForm')),
  },
  '/profile': {
    exact: true,
    component: asyncComponent(() => import('./containers/Profile')),
  },
  '/profile/messengers': {
    exact: true,
    component: asyncComponent(() => import('./containers/NotificationSettings')),
  },
  '/user/:id': {
    exact: true,
    component: asyncComponent(() => import('./containers/User')),
  },
  '/profile/edit': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/ProfileForm')),
  },
  '/clients': {
    exact: true,
    component: asyncComponent(() => import('./containers/Clients')),
  },
  '/client/:id': {
    exact: true,
    component: asyncComponent(() => import('./containers/Client')),
  },
  '/client/:id/edit': {
    exact: true,
    component: asyncComponent(() => import('./containers/forms/ClientForm')),
    access: ['dispatcher'],
  },
  '/locations': {
    exact: true,
    component: asyncComponent(() => import('./containers/Locations')),
  },
  '/users/:role': {
    exact: true,
    component: asyncComponent(() => import('./containers/UsersList')),
    access: ['dispatcher'],
  },
  '/types-of-work': {
    exact: true,
    component: asyncComponent(() => import('./containers/TypesOfWork')),
    access: ['dispatcher'],
  },
  '/urgency': {
    exact: true,
    component: asyncComponent(() => import('./containers/Urgency')),
    access: ['dispatcher'],
  },
  '/analytics/per-object': {
    exact: true,
    component: asyncComponent(() => import('./containers/ObjectAnalytics')),
    access: ['dispatcher', 'client', 'boss'],
  },
  '/analytics/per-user': {
    exact: true,
    component: asyncComponent(() => import('./containers/UserAnalytics')),
    access: ['dispatcher'],
  },
  '/scheduler': {
    exact: true,
    component: asyncComponent(() => import('./containers/Scheduler')),
    access: ['dispatcher'],
  },
};

export default routes;
