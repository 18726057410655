import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import request from '../../helpers/request';

export function* setBranding() {
  yield takeEvery(actions.GET_BRANDING, function* () {
    try {
      const req = () => request
        .get('/api/branding');

      const response = yield call(req);
      yield put({
        type: actions.SET_BRANDING,
        branding: response.body,
      });
    } catch (e) {
      // Error handle.
      console.log(e);
      yield put({
        type: actions.SET_BRANDING,
        branding: null,
      });
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(setBranding),
  ]);
}
