import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import io from 'socket.io-client';
import { notification } from 'antd';
import actions from '../../redux/notifications/actions';

const messages = defineMessages({
  onUpdateFound: {
    id: 'onUpdateFound',
  },
  onUpdateFoundTitle: {
    id: 'onUpdateFoundTitle',
  },
});

class SocketConnector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reg: null,
    };
  }
  componentDidMount() {
    const { profile, intl } = this.props;
    const socket = io(process.env.REACT_APP_SOCKET_URL.replace('/socket.io', ''));
    socket.emit('set uuid', profile.id);
    socket.on('new message', this.handleMessage);
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        if (reg) {
          this.setState({ reg });
          if (typeof reg.connectToSocket === 'function') {
            reg.connectToSocket(profile.id);
          }
          // Handle newly available content.
          setTimeout(() => {
            if (reg.showUpdateNotification) {
              notification.warning({
                message: intl.formatMessage(messages.onUpdateFoundTitle),
                description: intl.formatMessage(messages.onUpdateFound),
              });
            }
          }, 5000);
        }
      });
    }
  }

  handleMessage = (message) => {

    const { pushComment, pushTask } = this.props;
    if (!message || !message.message) {
      return null;
    }
    if (message.message.notification) {
      pushTask(message.message.notification);
    }
    if (message.message.comment) {
      pushComment(message.message.comment);
    }
    return null;
  };

  render() {
    return null;
  }
}

SocketConnector.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  pushTask: PropTypes.func.isRequired,
  pushComment: PropTypes.func.isRequired,
  intl: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  profile: state.Auth.profile,
});

const mapDispatchToProps = dispatch => ({
  pushTask: task => dispatch(actions.pushTask(task)),
  pushComment: comment => dispatch(actions.pushComment(comment)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SocketConnector));
