import { Spin } from 'antd';
import styled from 'styled-components';
import { palette } from 'styled-theme';

const StyledSpin = styled(Spin)`
  &.ant-spin-lg {
        .ant-spin-dot {
          font-size: 64px;
          
          i {
            width: 28px;
            height: 28px;
          }
        }
      }
  && {
    .ant-spin-dot {
      i {
        background-color: ${palette('primary', 0)};
      }
    }    
  }
`;

export default StyledSpin;
