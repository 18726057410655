import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import appActions from '../../redux/app/actions';
import TopbarNotification from './topbarNotification';
import TopbarMessage from './topbarMessage';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import AddButton from './addButton';
import TimeTracker from '../TimeTracker';

const { Header } = Layout;

const TopBar = ({ toggleCollapsed, customizedTheme, collapsed, openDrawer }) => {
  const isCollapsed = collapsed && !openDrawer;
  const styling = {
    background: customizedTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
    height: 70,
  };
  return (
    <TopbarWrapper>
      <Header
        style={styling}
        className={
          isCollapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
        }
      >
        <div className="isoLeft">
          <button
            className={
              isCollapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'
            }
            style={{ color: customizedTheme.textColor }}
            onClick={toggleCollapsed}
          />
          <TimeTracker />
        </div>

        <ul className="isoRight">
          <li>
            <AddButton />
          </li>
          <li className="isoNotify">
            <TopbarNotification />
          </li>
          <li className="isoMsg">
            <TopbarMessage />
          </li>
          <li className="isoUser">
            <TopbarUser />
          </li>
        </ul>
      </Header>
    </TopbarWrapper>
  );
};

TopBar.propTypes = {
  toggleCollapsed: PropTypes.func.isRequired,
  customizedTheme: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
  }).isRequired,
  collapsed: PropTypes.bool,
  openDrawer: PropTypes.bool,
};

TopBar.defaultProps = {
  collapsed: false,
  openDrawer: false,
};

export default connect(
  state => ({
    ...state.App,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
  }),
  { toggleCollapsed: appActions.toggleCollapsed },
)(TopBar);
