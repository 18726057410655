import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch, matchPath } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';
import Auth0 from './authentication/auth0';
import routes from './routes';
import GlobalLoader from './components/GlobalLoader';
import { checkAccess } from './helpers/user';

const getPath = (path) => {
  if (path in routes) {
    return routes[path];
  }
  const route = Object.keys(routes).find((item) => {
    const match = matchPath(path, { path: item, exact: routes[item].exact });
    return match !== null && typeof match === 'object';
  });
  if (route) {
    return routes[route];
  }
  return false;
};

const RestrictedRoute = ({ component: Component, isLoggedIn, location, profile, modules, ...rest }) => {
  const path = getPath(location.pathname);

  if (path || location.pathname === '/') {
    if (isLoggedIn) {
      // Check enabled modules.
      if (location.pathname === '/scheduler' && !modules.scheduler) {
        return (
          <Route
            component={asyncComponent(() => import('./containers/Page/403'))}
          />
        );
      }
      if (!checkAccess(profile, path.access)) {
        return (
          <Route
            component={asyncComponent(() => import('./containers/Page/403'))}
          />
        );
      }
      return (
        <Route
          {...rest}
          render={props => <Component {...props} />}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={() => (<Redirect
          to={{
            pathname: '/signin',
            state: { from: location },
          }}
        />)}
      />
    );
  }
  return (
    <Route
      component={asyncComponent(() => import('./containers/Page/404'))}
    />
  );
};

RestrictedRoute.propTypes = {
  component: PropTypes.shape({}).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
  profile: PropTypes.shape({}).isRequired,
  modules: PropTypes.shape({}).isRequired,
};

RestrictedRoute.defaultProps = {
  location: {},
};

const PublicRoutes = ({ history, isLoggedIn, accountReady, profile, modules }) => {
  if (!accountReady) {
    return (
      <GlobalLoader />
    );
  }
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path={'/404'}
          component={asyncComponent(() => import('./containers/Page/404'))}
        />
        <Route
          exact
          path={'/500'}
          component={asyncComponent(() => import('./containers/Page/500'))}
        />
        <Route
          exact
          path={'/signin'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          exact
          path={'/signup'}
          component={asyncComponent(() => import('./containers/Page/signup'))}
        />
        <Route
          exact
          path={'/forgotpassword'}
          component={asyncComponent(() =>
            import('./containers/Page/forgotPassword')
          )}
        />
        <Route
          exact
          path={'/user/reset/:id/:timestamp/:token'}
          component={asyncComponent(() =>
            import('./containers/Page/resetPassword')
          )}
        />

        <Route
          path="/auth0loginCallback"
          render={(props) => {
            Auth0.handleAuthentication(props);
          }}
        />
        <RestrictedRoute
          path="/"
          component={App}
          isLoggedIn={isLoggedIn}
          profile={profile}
          modules={modules}
        />
        <Route
          component={asyncComponent(() => import('./containers/Page/404'))}
        />
      </Switch>
    </ConnectedRouter>
  );
};

PublicRoutes.propTypes = {
  history: PropTypes.shape({}).isRequired,
  isLoggedIn: PropTypes.bool,
  accountReady: PropTypes.bool,
  profile: PropTypes.shape({}),
  modules: PropTypes.shape({}),
};

PublicRoutes.defaultProps = {
  isLoggedIn: false,
  accountReady: false,
  profile: null,
};

export default connect(state => ({
  isLoggedIn: state.Auth.loggedIn,
  accountReady: state.Auth.ready,
  profile: state.Auth.profile,
  modules: state.GlobalConfig.modules,
}))(PublicRoutes);
