import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import routes from '../../routes';

const AppRouter = ({ style }) => (
  <div style={style}>
    {Object.keys(routes).map((path) => {
      const { exact, ...otherProps } = routes[path];
      return (
        <Route
          exact={exact}
          key={path}
          path={path}
          {...otherProps}
        />
      );
    })}
  </div>
);

AppRouter.propTypes = {
  style: PropTypes.shape({}),
};

AppRouter.defaultProps = {
  style: null,
};

export default AppRouter;
