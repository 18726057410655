import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popover } from 'antd';
import IntlMessages from '../../components/utility/intlMessages';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import Avatar from '../../components/Avatar';
import getImage from '../../helpers/getImage';

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      avatar: null,
    };
  }

  async componentDidMount() {
    const { profile } = this.props;
    const data = await getImage(profile.avatar);
    this.setState({ avatar: data });
  }

  handleVisibleChange =() => this.setState({ visible: !this.state.visible });

  formatName = () => {
    const { profile } = this.props;
    const nameParts = [];
    if (profile.name) {
      nameParts.push(profile.name);
    }
    if (profile.surname) {
      nameParts.push(profile.surname);
    }
    if (nameParts.length) {
      return nameParts.join(' ');
    }
    return profile.login;
  };

  render() {
    const { logout } = this.props;
    const { avatar, visible } = this.state;
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Link className="isoDropdownLink" to={'/profile'}>
          <IntlMessages id="topbar.myprofile" />
        </Link>
        <Link className="isoDropdownLink" to="/profile/messengers">
          <IntlMessages id="topbar.messengers" />
        </Link>
        <Link className="isoDropdownLink" to="/help">
          <IntlMessages id="topbar.help" />
        </Link>
        <a className="isoDropdownLink" onClick={logout} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <Avatar alt="user" src={avatar} name={this.formatName()} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.Auth.profile,
});

TopbarUser.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { logout: authAction.logout },
)(TopbarUser);
