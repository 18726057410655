import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout, Icon } from 'antd';
import options from './options';
import Scrollbars from '../../components/utility/customScrollBar.js';
import Menu from '../../components/uielements/menu';
import IntlMessages from '../../components/utility/intlMessages';
import SidebarWrapper from './sidebar.style';
import appActions from '../../redux/app/actions';
import Logo from '../../components/utility/logo';
import { checkAccess } from '../../helpers/user';

const SubMenu = Menu.SubMenu;

const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

const renderIcon = (icon) => {
  if (icon.indexOf('anticon-') === 0) {
    return <Icon type={icon.replace('anticon-', '')} />;
  }
  return <i className={icon} />;
};

class Sidebar extends Component {
  handleClick = (e) => {
    this.props.changeCurrent([e.key]);
    if (this.props.app.view === 'MobileView') {
      setTimeout(() => {
        this.props.toggleCollapsed();
        this.props.toggleOpenDrawer();
      }, 100);
    }
  };
  onOpenChange = (newOpenKeys) => {
    const { app, changeOpenKeys } = this.props;
    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  };
  getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };
  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { profile } = this.props;
    const { key, label, leftIcon, children, access } = singleOption;
    if (!checkAccess(profile, access)) {
      return null;
    }
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className="isoMenuHolder" style={submenuColor}>
              {renderIcon(leftIcon)}
              <span className="nav-text">
                <IntlMessages id={label} />
              </span>
            </span>
          }
        >
          {children.map(child => (
            <Menu.Item style={submenuStyle} key={child.key}>
              <Link style={submenuColor} to={`/${child.key}`}>
                <IntlMessages id={child.label} />
              </Link>
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`/${key}`}>
          <span className="isoMenuHolder" style={submenuColor}>
            {renderIcon(leftIcon)}
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  render() {
    const { app, toggleOpenDrawer, customizedTheme, height, helpPhone, modules } = this.props;
    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    const { openDrawer } = app;
    const mode = collapsed === true ? 'vertical' : 'inline';
    const onMouseEnter = () => {
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
    };
    const onMouseLeave = () => {
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
      backgroundColor: 'rgba(0,0,0,0.3)',
      color: customizedTheme.textColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    let menuOptions = options;
    if (!modules.scheduler) {
      menuOptions = options.filter(o => o.key !== 'scheduler');
    }
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: height - 70 }} className="sidebar-scrollbar">
            <Menu
              onClick={this.handleClick}
              className="isoDashboardMenu"
              mode={mode}
              openKeys={collapsed ? [] : app.openKeys}
              selectedKeys={app.current}
              onOpenChange={this.onOpenChange}
            >
              {menuOptions.map(singleOption =>
                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}
            </Menu>
            {!collapsed && (
              <div className="sidebar-bottom">
                {helpPhone !== null && (
                  <div className="sidebar-bottom__item">
                    <div className="sidebar-bottom__item--content">
                      <i className="icon-warning text-danger" /><a href={`tel:+${helpPhone.replace(/[^\d.-]/g, '')}`}>{helpPhone}</a>
                    </div>
                  </div>
                )}
                <div className="sidebar-bottom__item">
                  <div className="sidebar-bottom__item--info">По вопросам работы программы</div>
                  <div className="sidebar-bottom__item--content">
                    <i className="icon-info text-muted" /><a href="tel:+375296448070">+ 375 (29) 644 80 70</a>
                  </div>
                </div>
                <div className="sidebar-bottom__item">
                  <div className="sidebar-bottom__item--content">
                    <Icon type="question-circle" className="text-muted" />
                    <a
                      href="https://slides.com/plague/cmms-coobee-manual/fullscreen"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Инструкция по работе</a>
                  </div>
                </div>
              </div>
            )}
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

Sidebar.propTypes = {
  app: PropTypes.shape({
    view: PropTypes.string,
  }).isRequired,
  profile: PropTypes.shape({}).isRequired,
  customizedTheme: PropTypes.shape({}).isRequired,
  height: PropTypes.number.isRequired,
  toggleOpenDrawer: PropTypes.func.isRequired,
  changeOpenKeys: PropTypes.func.isRequired,
  changeCurrent: PropTypes.func.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  helpPhone: PropTypes.string,
  modules: PropTypes.shape({}).isRequired,
};

Sidebar.defaultProps = {
  helpPhone: null,
};

export default connect(
  state => ({
    app: state.App,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
    height: state.App.height,
    profile: state.Auth.profile,
    helpPhone: state.GlobalConfig.helpPhone,
    modules: state.GlobalConfig.modules,
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed },
)(Sidebar);
