const actions = {
  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_STATUS_FILTER: 'SET_STATUS_FILTER',
  SET_PAGER: 'SET_PAGER',
  SET_TASK_ID_FILTER: 'SET_TASK_ID_FILTER',
  RESET_TASK_ID_FILTER: 'RESET_TASK_ID_FILTER',
  setFilters: taskFilter => ({
    type: actions.SET_FILTERS,
    taskFilter,
  }),
  setStatusFilter: statusFilter => ({
    type: actions.SET_STATUS_FILTER,
    statusFilter,
  }),
  setPager: pager => ({
    type: actions.SET_PAGER,
    pager,
  }),
  resetFilters: () => ({
    type: actions.RESET_FILTERS,
  }),
  setTaskIdFilter: ids => ({
    ids,
    type: actions.SET_TASK_ID_FILTER,
  }),
  resetTaskIdFilter: () => ({
    type: actions.RESET_TASK_ID_FILTER,
  }),
};

export default actions;
