import actions from './actions';

const initialState = {
  notifications: [],
  unreadTasks: [],
  unacceptedTasks: [],
  comments: [],
  unreadComments: {},
  unseenPhotos: [],
};

const pushUnreadComment = (comment, unread) => {
  const unreadComments = { ...unread };
  if (unreadComments[comment.target]) {
    unreadComments[comment.target].push(comment.commentId);
  } else {
    unreadComments[comment.target] = [comment.commentId];
  }
  return unreadComments;
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case actions.SET_UNREAD_TASKS:
      return {
        ...state,
        unreadTasks: action.tasks,
      };
    case actions.SET_UNSEEN_PHOTOS:
      return {
        ...state,
        unseenPhotos: action.photos,
      };
    case actions.SET_UNREAD_COMMENTS:
      return {
        ...state,
        unreadComments: action.comments,
      };
    case actions.SET_UNACCEPTED_TASKS:
      return {
        ...state,
        unacceptedTasks: action.tasks,
      };
    case actions.SET_COMMENTS:
      return {
        ...state,
        comments: action.comments,
      };
    case actions.UNSET_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.target !== action.id),
      };
    case actions.UNSET_COMMENT:
      return {
        ...state,
        comments: state.comments.filter(n => n.target !== action.id),
      };
    case actions.PUSH_TASK:
      state.unreadTasks.push(action.task.target);
      return {
        ...state,
        notifications: [action.task].concat(state.notifications),
        unreadTasks: [...state.unreadTasks],
      };
    case actions.PUSH_COMMENT:
      return {
        ...state,
        comments: [action.comment].concat(state.comments),
        unreadComments: pushUnreadComment(action.comment, state.unreadComments),
      };
    default:
      return state;
  }
};

export default notificationsReducer;
