import React from 'react';
import { Form, Icon } from 'antd';
import classNames from 'classnames';
import FormContext from 'antd/lib/form/context';
import Wrapper from './styles/formItem.style';

class FormItem extends Form.Item {
  renderWrapper(prefixCls, children) {
    return (
      <FormContext.Consumer key="wrapper">
        {({ wrapperCol: contextWrapperCol, vertical }) => {
          const { wrapperCol } = this.props;
          const mergedWrapperCol =
            ('wrapperCol' in this.props ? wrapperCol : contextWrapperCol) || {};

          const className = classNames(
            `${prefixCls}-item-control-wrapper`,
            mergedWrapperCol.className,
          );

          // No pass FormContext since it's useless
          return (
            <FormContext.Provider value={{ vertical }}>
              <Wrapper className={className}>
                {children}
              </Wrapper>
            </FormContext.Provider>
          );
        }}
      </FormContext.Consumer>
    );
  }

  renderValidateWrapper(prefixCls, c1, c2, c3) {
    const { props } = this;
    const onlyControl = this.getOnlyControl;
    const validateStatus =
      props.validateStatus === undefined && onlyControl
        ? this.getValidateStatus()
        : props.validateStatus;

    let classes = `${prefixCls}-item-control`;
    if (validateStatus) {
      classes = classNames(`${prefixCls}-item-control`, {
        'has-feedback': props.hasFeedback || validateStatus === 'validating',
        'has-success': validateStatus === 'success',
        'has-warning': validateStatus === 'warning',
        'has-error': validateStatus === 'error',
        'is-validating': validateStatus === 'validating',
        'has-value': props.value,
      });
    }
    let iconType = '';
    switch (validateStatus) {
      case 'success':
        iconType = 'check-circle';
        break;
      case 'warning':
        iconType = 'exclamation-circle';
        break;
      case 'error':
        iconType = 'close-circle';
        break;
      case 'validating':
        iconType = 'loading';
        break;
      default:
        iconType = '';
        break;
    }

    const icon =
      props.hasFeedback && iconType ? (
        <span className={`${prefixCls}-item-children-icon`}>
          <Icon type={iconType} theme={iconType === 'loading' ? 'outlined' : 'filled'} />
        </span>
      ) : null;

    return (
      <div className={classes}>
        {c1}
        {c2}
        {icon}
        {c3}
      </div>
    );
  }

  renderLabel(prefixCls) {
    return (
      <FormContext.Consumer key="label">
        {({
            vertical,
            colon: contextColon,
          }) => {
          const { label, colon, id, htmlFor, name } = this.props;
          const required = this.isRequired();

          let labelChildren = label;
          // Keep label is original where there should have no colon
          const computedColon = colon === true || (contextColon !== false && colon !== false);
          const haveColon = computedColon && !vertical;
          // Remove duplicated user input colon
          if (haveColon && typeof label === 'string' && (label).trim() !== '') {
            labelChildren = (label).replace(/[：:]\s*$/, '');
          }

          const labelClassName = classNames({
            [`${prefixCls}-item-required`]: required,
            [`${prefixCls}-item-no-colon`]: !computedColon,
          });

          return label ? (
            <label
              htmlFor={htmlFor || id || name || this.getId()}
              className={labelClassName}
              title={typeof label === 'string' ? label : ''}
              onClick={this.onLabelClick}
            >
              {labelChildren}
            </label>
          ) : null;
        }}
      </FormContext.Consumer>
    );
  }

  renderChildren(prefixCls) {
    const { children } = this.props;
    return [
      this.renderWrapper(
        prefixCls,
        this.renderValidateWrapper(
          prefixCls,
          children,
          this.renderLabel(prefixCls),
          this.renderHelp(prefixCls),
          this.renderExtra(prefixCls),
        ),
      ),
    ];
  }
}

export default FormItem;
