import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import Notification from './notification';
import actions from '../../redux/notifications/actions';
import Scrollbar from '../../components/utility/customScrollBar';

class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleVisibleChange = () => this.setState({ visible: !this.state.visible });

  render() {
    const { customizedTheme, notifications, markRead } = this.props;
    const content = (
      <TopbarDropdownWrapper className="topbarNotification">
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.notification" />
          </h3>
        </div>
        <div className="isoDropdownBody">
          <Scrollbar style={{ height: 300 }}>
            {notifications.map(notification => <Notification key={notification.id} {...notification} />)}
          </Scrollbar>
        </div>
        <div className="isoDropdownFooter">
          <button className="isoViewAllBtn no-btn" onClick={() => markRead('all')}>
            <IntlMessages id="topbar.clear" />
          </button>
        </div>
      </TopbarDropdownWrapper>
    );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomRight"
      >
        <div className="isoIconWrapper">
          <i
            className="ion-android-notifications"
            style={{ color: customizedTheme.textColor }}
          />
          {notifications.length > 0 && <span>{notifications.length}</span>}
        </div>
      </Popover>
    );
  }
}

TopbarNotification.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customizedTheme: PropTypes.shape({}).isRequired,
  markRead: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ...state.App,
  notifications: state.Notifications.notifications,
  customizedTheme: state.ThemeSwitcher.topbarTheme,
});

const mapDispatchToProps = dispatch => ({
  markRead: id => dispatch(actions.markRead(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopbarNotification);
