import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import IntlPolyfill from 'react-intl-polyfill';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { store, history } from './redux/store';
import PublicRoutes from './router';

import themes from './settings/themes';
import AppLocale from './languageProvider';
import config, {
  getCurrentLanguage,
} from './containers/LanguageSwitcher/config';
import { themeConfig } from './settings';
import CoobeeAppHolder from './CoobeeAppStyle';
import Boot from './redux/boot';
import GlobalStyles from './assets/styles/globalStyle';

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

const CoobeeApp = () => (
  <IntlPolyfill locales={['en-US', 'ru-RU']}>
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        {process.env.NODE_ENV === 'production' && (
          <>
            <Helmet>
              <script>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WGPQJJ9');`}
              </script>
            </Helmet>
            <noscript>
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-WGPQJJ9"
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
                title="gtm"
              >&nbsp;</iframe>
            </noscript>
          </>
        )}
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <CoobeeAppHolder>
            <Provider store={store}>
              <PublicRoutes history={history} />
            </Provider>
            <GlobalStyles />
          </CoobeeAppHolder>
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  </IntlPolyfill>
);
Boot()
  .then(() => CoobeeApp())
  .catch(error => console.error(error));

export default CoobeeApp;
export { AppLocale };
