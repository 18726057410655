import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import Dropdown, {
  DropdownMenu,
  MenuItem,
} from '../../components/uielements/dropdown';
import Button from '../../components/uielements/button';
import IntlMessages from '../../components/utility/intlMessages';
import topbarActions from '../../settings/topbarActions.json';
import { checkAccess } from '../../helpers/user';

const AddButton = ({ profile }) => {
  const actions = topbarActions.filter(a => checkAccess(profile, a.access));
  if (!actions.length) {
    return null;
  }
  if (actions.length === 1) {
    return (
      <Link to={actions[0].link}>
        <Button type="primary">
          <Icon type="plus" />
          <span className="hide-mobile">&nbsp;
            <IntlMessages id="topbar.add" />&nbsp;
            <IntlMessages id={actions[0].messageId} />
          </span>
        </Button>
      </Link>
    );
  }
  return (
    <Dropdown
      icon={<Icon type="down" />}
      trigger={['click']}
      type="primary"
      overlay={<DropdownMenu>
        {actions.map(action => (
          <MenuItem key={action.link}>
            <Link to={action.link}>
              <IntlMessages id={action.messageId} />
            </Link>
          </MenuItem>
        ))}
      </DropdownMenu>}
    >
      <Button type="primary">
        {<Icon type="plus" />}{<span className="hide-mobile">&nbsp;<IntlMessages id="topbar.add" /></span>}
      </Button>
    </Dropdown>
  );
};

AddButton.propTypes = {
  profile: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  profile: state.Auth.profile,
});

export default connect(mapStateToProps)(AddButton);
